import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import ReportSelector from 'selectors/report'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'
import { formatReportValue } from 'helper/utils'
import { getBarFill } from 'helper/viewHelper'
import ReportLabel from '../ReportLabel'
import ReportTooltip from '../ReportTooltip'
import ReportSummary from '../ReportSummary'
import { union, values } from 'lodash'
import './style.less'

import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
  ResponsiveContainer,
  // LabelList,
  YAxis,
} from 'recharts'
import { processStateOptions } from '../../../helper/viewHelper'

/* eslint-disable react/prefer-stateless-function */
class StackedBarChartReport extends Component {
  static propTypes = {
    selectedDetailReport: PropTypes.shape({}),
    selectedFlattenedDetailReport: PropTypes.arrayOf(PropTypes.shape({})),
    ...i18nPropTypes,
  }

  static defaultProps = {
    selectedDetailReport: {},
    selectedFlattenedDetailReport: [],
  }

  formatCurrency = (value) => {
    const { selectedDetailReport: { detailValueType } } = this.props
    return formatReportValue(value, detailValueType)
  }

  renderChart = () => {
    const { selectedDetailReport, selectedFlattenedDetailReport, selectedDetailReportDataKeys, selectedFilters, craftValues } = this.props

    const stacks = selectedFlattenedDetailReport.reduce((acc, report) => {
      return union(acc, Object.keys(report).slice(1).map(label => label.split('.').at(0)))
    }, [])

    let segmentValues = selectedDetailReportDataKeys.reduce((acc, report) => {
      return union(acc, [report.split('.').at(1)])
    }, []).sort()

    const unit = () => {
      switch (selectedDetailReport.detailValueType) {
        case 'CURRENCY':
          return '€'
        case 'DAYS':
          return ' Tage'
        case 'PERCENT':
          return '%'
        default:
          return ''
      }
    }
    const tickFormatter = (value) => {
      return value ? value.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }) + unit() : ''
    }

    let { segments } = selectedFilters
    segments = segments && selectedDetailReport.filters?.segments?.includes(segments)
      ? segments
      : selectedDetailReport.filters?.segments?.at(0)
    if (segments === 'crafts') {
      segmentValues = craftValues
    }

    const tooltipContent = <ReportTooltip tickFormatter={tickFormatter} segments={segments} />

    const stackSizes = selectedDetailReportDataKeys.reduce((acc, key) => {
      const stackId = key.split('.').at(0)
      if (!acc[stackId]) {
        acc[stackId] = 1
      } else {
        acc[stackId] += 1
      }
      return acc
    }, {})

    const maxStackSize = Math.max(...values(stackSizes))
    return (
      <div className="StackedBarChartReport">
        <div className="StackedBarChartReport__Summary">
          <ReportSummary
            data={selectedDetailReport?.overviewData}
            valueType={selectedDetailReport?.detailValueType}
          />
        </div>
        <div className="StackedBarChartReport__Title">
          {selectedDetailReport?.name || ''}
        </div>
        {/^Laufzeit/ig.test(selectedDetailReport?.name)
          ? (
            <div className="StackedBarChartReport__Subtitle">
              {this.props.t('reports.durationNote')}
            </div>
          )
          : null
        }
        <div className="StackedBarChartReport__ChartContainer">
          <ResponsiveContainer>
            <BarChart
              data={selectedFlattenedDetailReport}
              margin={{
                top: 20,
                right: 20,
                left: 80,
                bottom: 120,
              }}
              onClick={null}
              barSize={60 / Math.sqrt(selectedFlattenedDetailReport.length)}
              barGap={2}
            >
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <YAxis tickFormatter={tickFormatter} />
              <XAxis dataKey="label" dy={120} />
              <Tooltip content={tooltipContent} cursor={{ fill: "#eeeeee80" }} />
              {stacks.sort().map((stackId) => (
                <Bar stackId={stackId} key={`Label${stackId}`} data={0} label={<ReportLabel label={stackId} />} />
              ))}
              {selectedDetailReportDataKeys.sort((a, b) => {
                a = processStateOptions.indexOf(a.split('.').at(1))
                b = processStateOptions.indexOf(b.split('.').at(1))

                return a > b ? -1 : 1
              }).map((key, index) => {
                const [stackId, segmentId] = key.split('.')

                const { fill, fillOpacity } = getBarFill(key, stacks, segmentValues, segments, selectedDetailReportDataKeys, maxStackSize)
                return <Bar
                  key={key}
                  dataKey={key}
                  stackId={stackId}
                  fill={fill}
                  fillOpacity={fillOpacity}
                />
              })}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }

  renderNoData = () => (
    <div className="StackedBarChartReport__NoDataContainer">
      <div className="StackedBarChartReport__NoDataHeader">{this.props.t('reports.noData')}</div>
      <div className="StackedBarChartReport__NoDataInfo">{this.props.t('reports.noDataInfo')}</div>
    </div>
  )

  render = () => {
    const { selectedFlattenedDetailReport } = this.props

    if (selectedFlattenedDetailReport.length === 0) {
      return this.renderNoData()
    }
    return this.renderChart()
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(ownProps,state)

  return {
    // ...state,
    // selectedDetailReport: ownProps.selectedReport,
    craftValues: state.entities?.crafts ? Object.keys(state.entities.crafts).map((key) => state.entities.crafts[key].name).sort() : [],
    selectedFilters: ReportSelector.getSelectedFilters(state),
    selectedDetailReport: ReportSelector.getSelectedDetailReport(state),
    selectedFlattenedDetailReport: ReportSelector.getSelectedFlattenedDetailReport(state),
    selectedDetailReportDataKeys: ReportSelector.getSelectedDetailReportDataKeys(state).sort(),
  }
}

const connectedComponent = connect(mapStateToProps, {})(translate()(StackedBarChartReport))

export default AsyncDataLoadingHOC({
  selectedDetailReport: {
    idSelector: ReportSelector.getSelectedReportIdentifier,
    entityType: 'reports',
  },
})(connectedComponent)
